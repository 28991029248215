import { render, staticRenderFns } from "./PUCHistoryItems.vue?vue&type=template&id=57185d20&scoped=true&"
import script from "./PUCHistoryItems.vue?vue&type=script&lang=js&"
export * from "./PUCHistoryItems.vue?vue&type=script&lang=js&"
import style0 from "./PUCHistoryItems.vue?vue&type=style&index=0&id=57185d20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57185d20",
  null
  
)

export default component.exports