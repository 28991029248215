export default () => {
  return {
    meta: {
      title: 'History',
      description: 'Push Ups Counter allows you to count the number of push-ups you do and save them locally. No account needed and no application to install.'
    },

    content: {}
  }
}
